// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

import { fetchScenes } from "../scenes/scenesActions";

// create Provider
export const createProvider = createAsyncThunk(
  "provider/create",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`provider/`, data), rejectWithValue),
);

// get providers //! not used
export const fetchProviders = createAsyncThunk(
  "providers/fetchProvidersAll",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`provider/all/`), rejectWithValue),
);

// get providers from a specific company
export const fetchCompanyProviders = createAsyncThunk(
  "providers/fetchCompanyProviders",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`provider/company-providers/${companyId}`),
      rejectWithValue,
    ),
);

// refresh provider
export const refreshProvider = createAsyncThunk(
  "providers/refresh",
  async (id, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`provider/refresh-provider/${id}`),
      rejectWithValue,
    ),
);

// refresh all providers
export const refreshAllProvidersAndFetchScenes = createAsyncThunk(
  "studio/refreshAllAndFetchScenes",
  async (providers, { dispatch }) => {
    // Dispatch refreshProvider for each provider
    const refreshRequests = providers.map((provider) =>
      dispatch(refreshProvider(provider.mosys_id)),
    );
    await Promise.all(refreshRequests);
    // Once all providers are refreshed, fetch scenes
    await dispatch(fetchScenes());
  },
);

// update provider
export const updateProvider = createAsyncThunk(
  "provider/update/",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.patch(`provider/update/`, data),
      rejectWithValue,
    ),
);

// delete provider
export const deleteProvider = createAsyncThunk(
  "provider/delete/",
  async (providerId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.delete(`provider/delete/${providerId}/`),
      rejectWithValue,
    ),
);
