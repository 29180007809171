// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// get keywords  //!not used
export const fetchKeywords = createAsyncThunk(
  "keywords/fetchKeywordsAll",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("keyword/all/"), rejectWithValue),
);

// get companykeywords
export const fetchCompanyKeywords = createAsyncThunk(
  "keywords/fetchCompanyKeywords",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`keyword/company-keywords/${companyId}`),
      rejectWithValue,
    ),
);

// create keyword
export const createKeyword = createAsyncThunk(
  "keywords/create",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`keyword/create/`, data), rejectWithValue),
);

// delete keywords
export const deleteKeyword = createAsyncThunk(
  "keywords/delete",
  async (keywordId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.delete(`keyword/delete/${keywordId}`),
      rejectWithValue,
    ),
);
