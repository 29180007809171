import axios from "axios"; // promise-based HTTP client to make asynchronous HTTP requests >  allows you to intercept requests or responses with common headers (e.g., authentication tokens).
import { getAccessTokenGlobal } from "../../web-utils/src/context/authContext";

const REACT_APP_API_URL =
  window.env?.REACT_APP_API_URL || "https://api.dev.mosaic.mo-sys.com/api/v1/";

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  // withCredentials: true, //enable on production
  headers: {
    "Content-Type": "application/json",
  },
});

// Add interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessTokenGlobal();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
