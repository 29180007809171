// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const ZeroOffsets = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
        <path d="M4.41,20.2C5.67,12.1,13.55,4.02,24,4.01c7.93,0,14.6,4.3,18.12,11.23" />
        <polygon points="36.21 11.75 36.87 10.99 41.82 15.28 40.78 8.81 41.77 8.66 43.24 17.84 36.21 11.75" />
        <path d="M43.66,27.8c-1.27,8.09-9.14,16.18-19.59,16.19-7.93,0-14.6-4.3-18.12-11.23" />
        <polygon points="11.85 36.25 11.2 37.01 6.24 32.72 7.28 39.19 6.29 39.35 4.82 30.16 11.85 36.25" />
        <path d="M24,28.99c2.48,0,4.5,2.02,4.5,4.5s-2.02,4.5-4.5,4.5-4.5-2.02-4.5-4.5,2.02-4.5,4.5-4.5M24,27.99c-3.04,0-5.5,2.46-5.5,5.5s2.46,5.5,5.5,5.5,5.5-2.46,5.5-5.5-2.46-5.5-5.5-5.5h0Z" />
        <line x1="15.75" y1="33.49" x2="32.25" y2="33.49" />
        <text transform="translate(8.86 24.96)" style="fill: #fff; font-family: OpenSans-Regular, &apos;Open Sans&apos;; font-size: 12px; font-variation-settings: &apos;wght&apos; 400, &apos;wdth&apos; 100;"><tspan x="0" y="0">ZERO</tspan></text>
    </svg>
  );
};
