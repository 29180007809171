// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { Typography } from "../Typography/Typography";
import styles from "./Loader.module.css";

export const Loader = ({ small, loadingText = "Loading..." }) => {
  return (
    <div
      className={`${styles.loader} ${small ? styles.smallLoader : styles.default}`}
    >
      {!small ? (
        <Typography variant="h3" className={styles.loading_text}>
          {loadingText}
        </Typography>
      ) : null}
    </div>
  );
};
