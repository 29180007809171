// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import { addAsyncCases, updateStateStatus, clearState } from "../utils";

import {
  createProvider,
  fetchCompanyProviders,
  updateProvider,
  deleteProvider,
} from "./providersActions";

const initialState = {
  providers: [],
  status: { providers: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { providers: null },
};

const providersSlice = createSlice({
  name: "providers",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchCompanyProviders, key: "providers" },
    ]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(createProvider.fulfilled, (state, action) => {
        state.providers = [...state.providers, action.payload];
        updateStateStatus(state, "providers", "succeeded");
      })
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.providers.map((provider) =>
          provider.provider_id === action.payload.provider_id
            ? action.payload
            : provider,
        );

        updateStateStatus(state, "providers", "succeeded");
      })
      .addCase(deleteProvider.fulfilled, (state, action) => {
        state.providers = state.providers.filter(
          (p) => p.provider_id !== action.provider_id,
        );
        updateStateStatus(state, "providers", "succeeded");
      });
  },
});

export const providersStatus = (state) => state.providers.status;
export const providersError = (state) => state.providers.error;

export default providersSlice.reducer;
