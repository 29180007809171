// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Offline = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M45,26.31v15.8c0,1.21-.99,2.2-2.2,2.2H5c-1.1,0-2-.9-2-2v-16h42M46,25.31H2v17c0,1.66,1.34,3,3,3h37.8c1.77,0,3.2-1.43,3.2-3.2v-16.8h0Z" />
      <path
        d="M15.83,3.92c2.54-.95,5.28-1.45,8.09-1.46,6.35-.02,12.3,2.51,16.66,6.79"
        strokeLinecap="round"
      />
      <path d="M11.92,5.78c-1.63.97-3.14,2.13-4.5,3.46" strokeLinecap="round" />
      <path
        d="M19.16,8.33c1.56-.49,3.21-.75,4.89-.74,4.93.02,9.52,2.28,12.55,6"
        strokeLinecap="round"
      />
      <path
        d="M15.23,10.16c-1.45.92-2.75,2.07-3.84,3.41"
        strokeLinecap="round"
      />
      <path
        d="M22.48,12.71c.48-.09.99-.14,1.52-.14,4.87.02,7.66,4.46,7.97,4.97"
        strokeLinecap="round"
      />
      <path
        d="M18.59,14.59c-1.56,1.26-2.45,2.68-2.62,2.95"
        strokeLinecap="round"
      />
      <polyline
        points="11.46 1.64 13.82 4.77 17.11 9.12 35.08 32.88"
        strokeLinecap="round"
      />
    </svg>
  );
};
