// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// TODO : ADD ACTION FROM WEBSOCKET CONNECTION HERE

// fetch studio by Id
export const fetchStudioById = createAsyncThunk(
  "studioById",
  async (studioId, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`studio/${studioId}`), rejectWithValue),
);

// add scene to studio
export const addSceneToStudio = createAsyncThunk(
  "addSceneToStudio",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`studio/add/scene/`, data),
      rejectWithValue,
    ),
);

// get studio members
export const fetchStudiosMembers = createAsyncThunk(
  "studio/fetchStudioMember",
  async (studioId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`studio/studio-members/${studioId}/`),
      rejectWithValue,
    ),
);
