// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// get Scenes
export const fetchScenes = createAsyncThunk(
  "scenes/fetchScenesAll",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`scene/all/`), rejectWithValue),
);

// get scene by uuid
export const fetchSceneById = createAsyncThunk(
  "scenes/fetchSceneById",
  async (sceneId, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`scene/${sceneId}/`), rejectWithValue),
);

// add keyword to scene
export const addKeywordToScene = createAsyncThunk(
  "keyword/addToScene",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`scene/add/keyword/`, data),
      rejectWithValue,
    ),
);

// delete keyword from scene
export const deleteKeywordFromScene = createAsyncThunk(
  "keyword/deleteKeywordFromScene ",
  async ({ sceneId, keywordId }, { rejectWithValue }) =>
    apiCall(
      () =>
        axiosInstance.delete(
          `scene/delete/scenekeyword/${sceneId}/${keywordId}`,
        ),
      rejectWithValue,
    ),
);

// add unreal version to scene //TODO

// delete unreal version from scene //TODO

// buy scene //TODO

// filtered scenes
export const filteredScenes = createAsyncThunk(
  "scenes/filtered",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`scene/filtered/`, data), rejectWithValue),
);

// update scene
export const updateScene = createAsyncThunk(
  "scene/update",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.patch(`scene/update/`, data), rejectWithValue),
);

// delete scene
export const deleteScene = createAsyncThunk(
  "scene/delete",
  async (sceneId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.delete(`scene/delete/${sceneId}/`),
      rejectWithValue,
    ),
);

// TODO : SEPARATE  UNREAL VERSION  AS A DIFFERENT REDUCER
// get unreal versions
export const fetchUnrealVersion = createAsyncThunk(
  "unreal/fetchUnrealVersionAll",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`unreal/all/`), rejectWithValue),
);

// add unreal version //TODO
// delete unreal version //TODO
