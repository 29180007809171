// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/userSlice";
import studioReducer from "./studio/studioSlice";
import scenesReducer from "./scenes/scenesSlice";
import keywordsSlice from "./keywords/keywordsSlice";
import studiosSlice from "./studios/studiosSlice";
import companiesSlice from "./companies/companiesSlice";
import providersSlice from "./providers/providersSlice";
import showsSlice from "./shows/showsSlice";
import equipmentsSlice from "./equipments/equipmentsSlice";
import scriptsSlice from "./scripts/scriptsSlices";

// Combine multiple slices into a single reducer
const rootReducer = combineReducers({
  user: userReducer,
  companies: companiesSlice,
  shows: showsSlice,
  equipments: equipmentsSlice,
  scripts: scriptsSlice,
  studios: studiosSlice,
  scenes: scenesReducer,
  providers: providersSlice,
  studio: studioReducer,
  keywords: keywordsSlice,
});

// Persist configuration for the 'user' slice
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "companies",
    "shows",
    "equipments",
    "scripts",
    "studios",
    "providers",
    "keywords",
  ], // Only cache reduces in the array
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable ImmutableStateInvariantMiddleware
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"], // Ignore redux-persist actions
      },
    }),
});

// Export persistor for use in your application
export const persistor = persistStore(store);
