// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Edit = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <polygon points="33.04 19.68 8.24 44.47 3.46 44.3 3.78 40.01 28.57 15.21 33.04 19.68" />
      <rect
        x="34.17"
        y="7.82"
        width="6.18"
        height="6.32"
        transform="translate(3.15 29.56) rotate(-45)"
      />
    </svg>
  );
};
