// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAction } from "@reduxjs/toolkit";

// reset all states to initialState values
export const clearState = createAction("clearState");

// Utility to manage status, payloads, and errors
export const updateStateStatus = (state, key, type, action = null) => {
  state.status[key] = type;

  if (type === "succeeded") {
    if (action?.payload) {
      state[key] = action.payload;
    }
    state.error[key] = null;
  }
  if (type === "failed") {
    state.error[key] = action?.payload;
  }
};

// Utility function to handle common async cases on get routes
export const addAsyncCases = (builder, actions) => {
  actions.forEach(({ action, key }) => {
    builder
      .addCase(action.pending, (state) =>
        updateStateStatus(state, key, "loading"),
      )
      .addCase(action.fulfilled, (state, action) =>
        updateStateStatus(state, key, "succeeded", action),
      )
      .addCase(action.rejected, (state, action) =>
        updateStateStatus(state, key, "failed", action),
      );
  });
};

// Utility function for handling API requests and errors
export const apiCall = async (request, rejectWithValue) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error?.response?.data?.detail || // Check if 'detail' exists
        error?.response?.data?.detail?.msg || // Check for a detail msg  property in POST/PATCH request
        error?.message || // Fallback to the error message
        "An error occurred", // Final fallback message
    );
  }
};
