// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const DataOff = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <polyline points="42.78 9.54 42.4 9.54 15.54 9.54" />
      <line x1="3.54" y1="9.54" x2="0" y2="9.54" />
      <polygon points="39.47 13.62 38.8 12.89 42.4 9.54 38.8 6.19 39.47 5.46 43.86 9.54 39.47 13.62" />
      <polyline points="1.23 34.46 1.6 34.46 28.46 34.46" />
      <line x1="44" y1="34.46" x2="40.46" y2="34.46" />
      <polygon points="4.53 30.38 5.2 31.11 1.6 34.46 5.2 37.81 4.53 38.54 .14 34.46 4.53 30.38" />
      <line x1="1" y1="1" x2="43" y2="43" />
    </svg>
  );
};
