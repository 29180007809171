// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../../../../web-utils/src/context/authContext";

import { clearState } from "../../../../store/utils";

import { UserCard } from "../../../User/UserCard/UserCard";

import { Button } from "../../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { LinkComponent } from "../../../../../web-utils/src/UI/Component/React/V1/LinkComponent/LinkComponent";

import styles from "../Navigation.module.css";

const adminNav = [
  {
    name: "Companies",
    iconType: "devices",
    link: "/admin/companies",
  },
  {
    name: "Studios",
    iconType: "settings",
    link: "/admin/studios",
  },
];

export const UserNavigation = () => {
  const { logout } = useAuth();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleClearUser = () => {
    dispatch(clearState()); // Reset all redux states
    logout(); // remove stored cookies
  };

  return (
    <div className={styles.padding}>
      <div className={styles.borderBottom}>
        <UserCard user={user} flexDirection="column" />
      </div>
      <div className={styles.borderBottom}>
        {adminNav.map((item, index) => (
          <LinkComponent key={index} href={item.link} iconType={item.iconType}>
            {item.name}
          </LinkComponent>
        ))}
      </div>
      <div className={styles.item}>
        <Button
          color="light"
          onClick={handleClearUser}
          tooltipText={`Logout`}
          position="left"
          iconType="logout"
          sizing
        >
          Log out
        </Button>
      </div>
    </div>
  );
};
