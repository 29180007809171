// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.

import React from "react";

import { Avatar } from "../../../../web-utils/src/UI/Component/React/V1/Avatar/Avatar";
import { UserRole } from "../UserRole/UserRole";

import styles from "./UserCard.module.css";

export const UserCard = ({
  user,
  flexDirection = "row",
  extraInfo = true,
  userPermission,
  ...props
}) => {
  return (
    <>
      {!user ? null : (
        <>
          <div style={{ flexDirection }} className={styles.container}>
            <Avatar
              firstName={user?.first_name}
              lastName={user?.last_name}
              {...props}
            />
            <h4>{user.full_name}</h4>
          </div>

          {extraInfo && <p className={styles.info}>{user?.email}</p>}
          {userPermission && (
            <div className={styles.wrapper}>
              <UserRole role={userPermission} />
            </div>
          )}
        </>
      )}
    </>
  );
};
