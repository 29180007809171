// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { fetchUserInvites } from "../../../store/user/userActions";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { Badge } from "../../../../web-utils/src/UI/Component/React/V1/Badge/Badge";
import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";

import { PopOver } from "../../UI/PopOver/PopOver";

import { StudioNavigation } from "./StudioNavigation/StudioNavigation";
import { UserInvitations } from "./UserInvitations/UserInvitations";
import { UserNavigation } from "./UserNavigation/UserNavigation";

import MosysLogo from "../../../../web-utils/shared/img/MoSysLogo_r.svg";
import MoStudio from "../../../assets/logo/MoStudio.png";

import styles from "./Navigation.module.css";

import button from "../../UI/Button/Button.module.css";

export const Navigation = ({ navigation }) => {
  const { user } = useSelector((state) => state.user);
  const { studio } = useSelector((state) => state.studio);
  const dispatch = useDispatch();

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 576px)");

  const pendingInvitations = useMemo(
    () => user?.invites?.filter((el) => el.accepted === null),
    [user?.invites],
  );

  useEffect(() => {
    if (user?.user_id) {
      dispatch(fetchUserInvites());
    }
  }, [user?.user_id, dispatch]);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileOpen((prev) => !prev);
  }, []);

  const navItems = [
    {
      text: "Notifications",
      iconType: "notification",
      alert: (
        <>
          {!pendingInvitations?.length ? null : (
            <span className={styles.alert}>
              <Badge
                status={pendingInvitations?.length}
                variant="tertiary"
                round
              />
            </span>
          )}
        </>
      ),
      children: <UserInvitations pendingInvitations={pendingInvitations} />,
    },
    {
      text: "User",
      iconType: "user",
      children: <UserNavigation />,
    },
  ];

  return (
    <nav className={styles.nav}>
      <Link to="/" className={`${styles.logo} ${styles.mosys}`}>
        <MosysLogo />
      </Link>

      <Link to="/">
        <img src={MoStudio} alt="mosaic" className={styles.logo} />
      </Link>

      <div className={`${!navigation && styles.subnav}`}>
        {navigation && (
          <>
            <div className={styles.toggler}>
              <Button
                onClick={toggleMobileMenu}
                color="light"
                iconType="menu"
                iconColor="#434e5b"
              />
            </div>
            <div
              className={`${styles.navbar} ${isMobileOpen ? styles.show : ""}`}
            >
              <div className={styles.toggler}>
                <Button
                  onClick={toggleMobileMenu}
                  color="light"
                  iconType={isMobileOpen && "close"}
                  iconColor="#434e5b"
                />
              </div>
              <div className={styles.navItem}>
                <PopOver
                  color="primary"
                  classname={button.btnNavigation}
                  hoverEffect={true}
                  buttonText={
                    studio?.studio_uuid ? studio?.name : "All studios"
                  }
                >
                  <div className={styles.navItemContainer}>
                    <StudioNavigation />
                  </div>
                </PopOver>
              </div>
              <div className={styles.navItem}>
                <NavLink
                  to="library"
                  className={({ isActive }) =>
                    `${styles.link} ${button.btnNavigation} ${isActive ? styles.linkactive : ""}`
                  }
                >
                  <Icons iconType="librairy" />
                  {isMobile && "Library"}
                </NavLink>
              </div>
              {navItems.map((item, i) => (
                <div key={i} className={styles.navItem}>
                  <PopOver
                    iconType={item?.iconType}
                    color="primary"
                    hoverEffect={true}
                    classname={button.btnNavigation}
                    buttonText={
                      <>
                        {isMobile ? item.text : null}
                        {item?.alert}
                      </>
                    }
                  >
                    <div className={styles.navItemContainer}>
                      {item.children}
                    </div>
                  </PopOver>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};
