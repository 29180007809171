// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Lens = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M16.62,35.01l-12.06-.7c-1.64-3.07-2.56-6.58-2.56-10.31,0-4.43,1.31-8.56,3.56-12.01l5.58,11.59h0s.37.79.37.79l4.4,9.16.71,1.48Z" />
      <path d="M46,24c0,4.02-1.08,7.79-2.96,11.03l-5.81-10.63-.77-1.4-4.9-8.96h-.01l-.77-1.43,12.09.07c1.99,3.3,3.13,7.18,3.13,11.32Z" />
      <path d="M43.04,35.03c-3.81,6.56-10.91,10.97-19.04,10.97-.14,0-.29,0-.43-.01l6.45-10.2.85-1.35h.01s5.5-8.7,5.5-8.7l.85-1.34,5.81,10.63Z" />
      <path d="M30.02,35.79l-6.45,10.2h-.01c-8.25-.15-15.37-4.85-19-11.68l12.06.7,1.64.09,10.16.59,1.6.1Z" />
      <path d="M42.87,12.68l-12.09-.07h-1.62s-.02-.01-.02-.01l-10.14-.05h-1.66s5.91-10.53,5.91-10.53c.25-.02.5-.02.75-.02,8.01,0,15.02,4.28,18.87,10.68Z" />
      <path d="M23.25,2.02l-5.91,10.52-.79,1.41h-.01s-4.99,8.9-4.99,8.9l-.41.73-5.58-11.59C9.36,6.17,15.84,2.26,23.25,2.02Z" />
      <polygon points="37.23 24.4 36.38 25.74 30.88 34.43 30.87 34.44 30.02 35.79 28.42 35.69 18.26 35.1 16.62 35.01 15.91 33.53 11.51 24.37 11.14 23.59 11.14 23.58 11.55 22.85 16.54 13.96 16.55 13.95 17.34 12.54 18.98 12.55 19 12.55 29.14 12.6 29.16 12.6 30.77 12.61 30.78 12.61 31.55 14.04 31.56 14.04 36.46 23 37.23 24.4" />
    </svg>
  );
};
