// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import styles from "./Badge.module.css";

export const Badge = ({
  status,
  variant = "primary",
  outline,
  round,
  large,
}) => {
  return (
    <span
      style={{
        backgroundColor: `${outline ? "transparant" : `var(--color-${variant})`}`,
        color: `${outline ? `var(--color-${variant})` : `var(--color-dark)`}`,
        border: `${outline ? `1px solid var(--color-${variant})` : `none`}`,
      }}
      className={`${styles.badge} ${round ? styles.round : styles.default} ${large ? styles.largeText : styles.defaultText}`}
    >
      {status}
    </span>
  );
};
