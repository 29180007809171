// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Email = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <rect x="4" y="9.5" width="40" height="29" rx="3.23" ry="3.23" />
      <polyline points="43.84 11.73 24 25 4.16 11.73" />
    </svg>
  );
};
