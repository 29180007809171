// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Delete = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M12,19.07h24v21c0,1.66-1.34,3-3,3H15c-1.66,0-3-1.34-3-3v-21h0Z" />
      <polygon points="39 11.76 9 11.76 9 7.76 12.51 7.76 14.81 4.93 33.19 4.93 35.51 7.76 39 7.76 39 11.76" />
    </svg>
  );
};
