// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Share = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      stroke={color}
      fill="none"
      strokeWidth="2px"
    >
      <circle cx="11.36" cy="23.99" r="9.36" />
      <circle cx="30.22" cy="6.18" r="4.06" />
      <circle cx="40.92" cy="23.98" r="5.08" />
      <circle cx="30.22" cy="41.82" r="4.06" />
      <line x1="20.76" y1="24" x2="35.85" y2="24" />
      <line x1="17.24" y1="16.74" x2="27.08" y2="8.76" />
      <line x1="17.48" y1="31.06" x2="27.13" y2="39.22" />
    </svg>
  );
};
