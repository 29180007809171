// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const DataPacket = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <line x1="18.39" y1="34.57" x2="14.82" y2="33.97" />
      <line x1="43.25" y1="34.68" x2="46.82" y2="34.08" />
      <line x1="20.03" y1="30.82" x2="16.92" y2="28.92" />
      <line x1="22.65" y1="27.6" x2="20.24" y2="24.83" />
      <line x1="26.48" y1="25.33" x2="25.13" y2="21.89" />
      <line x1="30.83" y1="24.57" x2="30.8" y2="20.89" />
      <line x1="41.42" y1="30.78" x2="44.58" y2="28.95" />
      <line x1="38.8" y1="27.6" x2="41.21" y2="24.83" />
      <line x1="34.97" y1="25.33" x2="36.32" y2="21.89" />
      <path d="M36.37,43.27c-3.13,3.13-8.2,3.12-11.31,0s-3.13-8.19,0-11.31c2.69-2.69,6.82-3.07,9.91-1.11l-4.8,4.8,2.51,2.51,4.79-4.79c1.95,3.08,1.58,7.22-1.11,9.91Z" />
      <polyline points="34.97 30.84 30.17 35.64 32.69 38.15 37.48 33.36" />
      <path d="M-2.72,30.43c14.01,3.96,12.97-10.52,19.87-16.69,3.53-2.63,8.1-.23,11.94.32,4.76,1.17,7.32-.98,10.09-5.2" />
      <polyline points="18.33 34.54 -2.82 34.54 -2.82 5.94 39.18 5.94 39.18 20.63" />
      <circle cx="14.49" cy="17.08" r="1.54" />
      <circle cx="7.91" cy="28.64" r="1.54" />
      <circle cx="29.18" cy="14.26" r="1.54" />
      <circle cx="16.27" cy="32.32" r=".69" />
      <circle cx="5.11" cy="17.95" r=".69" />
      <circle cx="12.02" cy="9.66" r=".69" />
    </svg>
  );
};
