// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import { updateStateStatus, clearState } from "../utils";

import {
  fetchStudioById,
  addSceneToStudio,
  fetchStudiosMembers,
} from "./studioActions";

import { updateStudio } from "../studios/studiosActions";

const initialState = {
  studio: {
    studio_scenes: [],
    connected: false,
    members: [],
  },
  status: { studio: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { studio: null },
};

const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    clearStudio: (state) => {
      Object.assign(state, initialState);
    },
    clearError: (state) => {
      state.error.studio = null;
    },
    wsErrorMessage: (state, action) => {
      state.error.studio =
        action?.payload || "Unable to process incoming WebSocket message";
    },
    wsConnect: (state) => {
      state.studio = {
        ...state.studio,
        connected: true,
      };
    },
    wsErrorConnect: (state, action) => {
      state.studio = {
        ...state.studio,
        connected: false,
      };
      state.error.studio = action.payload;
    },
    sceneInStudioChanged: (state, action) => {
      state.studio = {
        ...state.studio,
        studio_scenes: state.studio.studio_scenes.map((scene) =>
          scene?.scene?.scene_uuid === action.payload?.sceneUUID
            ? {
                ...scene,
                status: action.payload?.status,
                download_percent:
                  action.payload?.status === "downloading" ||
                  action.payload?.status === "loading"
                    ? action.payload?.downloadPercentage
                    : scene.download_percent,
              }
            : scene,
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(fetchStudioById.fulfilled, (state, action) => {
        state.studio = { ...state.studio, ...action.payload };
        updateStateStatus(state, "studio", "succeeded");
      })
      .addCase(updateStudio.fulfilled, (state, action) => {
        if (state.studio.studio_uuid === action.payload.studio_uuid) {
          state.studio = {
            ...state.studio,
            ...action.payload,
          };
        }
        updateStateStatus(state, "studio", "succeeded");
      })
      .addCase(addSceneToStudio.fulfilled, (state, action) => {
        state.studio = {
          ...state.studio,
          studio_scenes: [
            ...(state.studio?.studio_scenes || []),
            action.payload,
          ],
        };
        updateStateStatus(state, "studio", "succeeded");
      })
      .addCase(fetchStudiosMembers.fulfilled, (state, action) => {
        state.studio = {
          ...state.studio,
          members: action.payload,
        };
        updateStateStatus(state, "studio", "succeeded");
      });
  },
});

export const {
  clearError,
  clearStudio,
  wsConnect,
  wsErrorConnect,
  wsErrorMessage,
  sceneInStudioChanged,
} = studioSlice.actions;

export const selectStudioStatus = (state) => state.studio.status;

export default studioSlice.reducer;
