// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Search = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="17.03" cy="17.68" r="10" />
      <line x1="39.19" y1="39.78" x2="25.58" y2="26.16" strokeLinecap="round" />
    </svg>
  );
};
