// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { useDispatch } from "react-redux";

import { useMediaQuery } from "../../../../hooks/useMediaQuery";

import { acceptCompanyInvitation } from "../../../../store/user/userActions";

import { CardLink } from "../../../UI/CardLink/CardLink";

import { Button } from "../../../../../web-utils/src/UI/Component/React/V1/Button/Button";

import styles from "../Navigation.module.css";

export const UserInvitations = ({ pendingInvitations }) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const dispatch = useDispatch();

  const handleAcceptInvitation = (invitation) => {
    dispatch(
      acceptCompanyInvitation({
        company_id: Number(invitation.company_id),
        member_email: invitation.member_email,
        token: invitation.token,
      }),
    );
  };
  return (
    <div className={styles.padding}>
      <h3 className={styles.borderBottom}>Join requests</h3>
      {pendingInvitations?.length ? (
        <div>
          {pendingInvitations?.map((el, i) => (
            <CardLink key={i} view={false}>
              <div className={styles.card}>
                <p>{el.company_id} invited you.</p>
                <Button
                  onClick={() => handleAcceptInvitation(el)}
                  iconType="isSuccess"
                  color="success"
                  iconColor={`var(--color-success)`}
                  round={isMobile}
                >
                  {!isMobile && "Accept"}
                </Button>
              </div>
            </CardLink>
          ))}
        </div>
      ) : (
        <div className={styles.item}>
          <p>No invitations found</p>
        </div>
      )}
    </div>
  );
};
