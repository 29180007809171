import React, { createContext, useContext, useEffect, useState } from "react";

// create a context with initial state
const OSContext = createContext();

export const OSContextProvider = ({ children }) => {
  const [ipAddress, setIpAddress] = useState(null);
  const [version, setVersion] = useState(null);

  useEffect(() => {
    const address = process.env.IP_ADDRESS;
    if (address.length > 0) {
      setIpAddress(address);
    }
    setVersion(`v${process.env.GIT_TAG} (${process.env.GIT_BUILD})`);
  }, []);

  return (
    <OSContext.Provider value={{ ipAddress, version }}>
      {children}
    </OSContext.Provider>
  );
};

export const useOSContext = () => {
  const context = useContext(OSContext);
  if (context === undefined) {
    throw new Error("useOSContext must be used within a OSContextProvider");
  }
  return context;
};
