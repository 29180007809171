import React from "react";

export const Actions = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="none"
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <polygon points="0 0 48 24 0 48 0 0" />
    </svg>
  );
};
