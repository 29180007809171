// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from 'react';

export const User = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
    >
      <circle cx="24" cy="14.39" r="8.5" />
      <path d="M39.14,30.73c0,6.63-6.49,14.44-14.5,14.44s-14.5-7.82-14.5-14.44c0-5.18,29-5.18,29,0Z" />
    </svg>
  );
};
