// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Mood = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="none"
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <path d="M.4,33.7c0,1.6.4,3,1.2,4.4s1.8,2.4,3.2,3.2c1.3.8,2.8,1.2,4.3,1.2h19c1.6,0,3-.4,4.4-1.2s2.4-1.8,3.2-3.2,1.2-2.8,1.2-4.4-.2-2.3-.7-3.5c1.3-1.6,2-3.6,2-5.8s-.2-2.4-.7-3.6-1.1-2.1-2-2.9-1.8-1.5-2.9-2c-1.1-.5-2.3-.7-3.6-.7-2.6,0-4.9,1-6.8,3.1-1.4-.8-2.9-1.2-4.7-1.2s-4.7.8-6.5,2.3c-1.9,1.5-3.1,3.5-3.7,5.9-2,.5-3.6,1.5-4.8,3s-1.9,3.4-1.9,5.4ZM3.4,33.7c0-1.5.5-2.7,1.5-3.8,1-1.1,2.2-1.7,3.7-1.9h.9c.2,0,.3-.2.3-.4v-.9c.4-1.9,1.2-3.5,2.6-4.8,1.4-1.3,3.1-1.9,4.9-1.9s3.6.6,5,1.9c1.4,1.3,2.2,2.9,2.4,4.8v1c.2.2.3.3.5.3h2.8c1.5,0,2.9.6,4,1.7s1.7,2.4,1.7,4-.6,2.9-1.7,4.1-2.5,1.7-4,1.7H9.1c-1.5,0-2.9-.6-4-1.7-1.1-1.1-1.7-2.5-1.7-4.1ZM15.2,12.2c0,.4.1.8.4,1.1l1.2,1.1c.4.3.8.5,1.1.4.4,0,.7-.2,1-.5s.4-.7.4-1.1-.2-.8-.5-1l-1-1.2c-.3-.3-.7-.4-1.1-.4s-.8.1-1.1.4c-.3.3-.4.6-.4,1.1ZM24.5,19.9c1.2-1.2,2.6-1.8,4.3-1.8s3.2.6,4.4,1.8,1.8,2.7,1.8,4.4-.3,2.2-.9,3.2c-1.7-1.7-3.7-2.5-6.1-2.5h-.6c-.4-1.9-1.4-3.6-3-5.2ZM27.4,10.7c0,.5.1.8.4,1.1s.6.4,1,.4.8-.1,1.1-.4c.3-.3.4-.6.4-1.1v-3.6c0-.4-.1-.8-.4-1s-.6-.4-1.1-.4-.8.1-1,.4c-.3.3-.4.6-.4,1v3.6ZM37.1,14.7c0,.5.1.8.4,1.1.4.3.7.4,1.1.4s.7-.1,1-.4l2.5-2.5c.3-.3.4-.7.4-1.1s-.1-.8-.4-1.1-.6-.4-1-.4-.8.1-1,.4l-2.6,2.5c-.3.3-.4.7-.4,1.1ZM38.5,35.5c0,.4.1.8.4,1.1l1.1,1.1c.3.3.6.4,1,.4s.8-.1,1.1-.4.4-.6.4-1.1-.1-.7-.4-1l-1.1-1.1c-.3-.3-.6-.4-1-.4s-.8.1-1.1.4c-.3.3-.5.6-.5,1.1ZM41.1,24.4c0,.4.2.7.5,1,.3.3.6.4,1.1.4h3.6c.4,0,.7-.1,1-.4s.4-.6.4-1-.1-.8-.4-1.1-.6-.4-1-.4h-3.6c-.4,0-.8.1-1.1.4-.3.3-.5.6-.5,1.1Z" />
    </svg>
  );
};
