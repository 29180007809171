// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// get company equipments
export const fetchCompanyEquipments = createAsyncThunk(
  "equipments/fetchCompanyEquipments",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`company_equipment/${companyId}/all/`),
      rejectWithValue,
    ),
);

// get company equipment detail
export const fetchCompanyEquipmentDetail = createAsyncThunk(
  "equipments/fetchCompanyEquipmentDetail",
  async ({ companyId, equipmentId }, { rejectWithValue }) =>
    apiCall(
      () =>
        axiosInstance.get(
          `company_equipment/${companyId}/detail/${equipmentId}/`,
        ),
      rejectWithValue,
    ),
);

// get studio equipments
export const fetchStudioEquipments = createAsyncThunk(
  "equipments/fetchStudioEquipments",
  async (studioId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`studio_equipment/${studioId}/`),
      rejectWithValue,
    ),
);

// get all company equipment types
export const fetchCompanyEquipmentTypes = createAsyncThunk(
  "equipments/fetchCompanyEquipmentTypes",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`equipment_type/company/${companyId}/`),
      rejectWithValue,
    ),
);

// get equipment type by id
export const fetchEquipmentTypeById = createAsyncThunk(
  "equipments/fetchEquipmentTypeById",
  async (equimentTypeId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`equipment_type/${equimentTypeId}/`),
      rejectWithValue,
    ),
);

// create equipment type
export const createEquipmentType = createAsyncThunk(
  "equipments/createEquipmentType",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`equipment_type/create/`, data),
      rejectWithValue,
    ),
);

// get equipment property for equipment
export const fetchEquipmentProperty = createAsyncThunk(
  "equipments/fetchEquipmentProperty",
  async (equimentPropertyId, { rejectWithValue }) =>
    apiCall(
      () =>
        axiosInstance.get(
          `equipment_type/property/choice/all/${equimentPropertyId}/`,
        ),
      rejectWithValue,
    ),
);

// Get show equipments
export const fetchShowEquipments = createAsyncThunk(
  "shows/fetchShowEquipments",
  async ({ showId, companyId }, { rejectWithValue }) => {
    try {
      if (!showId || !companyId) return;
      const response = await axiosInstance.get(
        `show_equipment/${showId}/all/`,
        {
          params: { company_uuid: companyId }, // Add company_uuid as a query parameter
        },
      );
      return response.data;
    } catch (error) {
      // backend sending 404 if no scenes found instead of empty array
      if (error?.response?.status === 404) {
        return [];
      }
      return rejectWithValue(
        error?.response?.data?.detail ||
          error?.response?.data?.detail?.msg ||
          error?.message ||
          "An error occurred",
      );
    }
  },
);

// filter show equipment by type
export const filterShowEquipmentByType = createAsyncThunk(
  "shows/filterShowEquipmentByType",
  async ({ showId, equipmentTypeId, companyId }, { rejectWithValue }) => {
    if (!showId || !companyId) return;
    return apiCall(
      () =>
        axiosInstance.get(`show_equipment/${showId}/type/${equipmentTypeId}/`, {
          params: { company_uuid: companyId }, // Add company_uuid as a query parameter
        }),
      rejectWithValue,
    );
  },
);

// Get show equipment detail
export const fetchShowEquipmentDetails = createAsyncThunk(
  "shows/fetchShowEquipmentDetails",
  async ({ showId, showEquipmentId, companyId }, { rejectWithValue }) => {
    if (!showId || !companyId) return;
    return apiCall(
      () =>
        axiosInstance.get(
          `show_equipment/${showId}/detail/${showEquipmentId}/`,
          {
            params: { company_uuid: companyId }, // Add company_uuid as a query parameter
          },
        ),
      rejectWithValue,
    );
  },
);
