// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Help = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="23" cy="23" r="21" />
      <path
        d="M19,17.75c.35-2.44,2.3-4.32,4.64-4.63,3.06-.4,5.35,1.8,5.36,3.66.01,2.49-2.94,4.82-3.83,5.67-2.03,1.94-1.95,6.33-1.91,6.72"
        strokeLinecap="round"
      />
      <circle cx="23.3" cy="36.48" r=".39" />
    </svg>
  );
};
