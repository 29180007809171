// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import { addAsyncCases, updateStateStatus, clearState } from "../utils";

import {
  fetchCompanyEquipments,
  fetchCompanyEquipmentDetail,
  fetchStudioEquipments,
  fetchShowEquipments,
  filterShowEquipmentByType,
  fetchShowEquipmentDetails,
  fetchCompanyEquipmentTypes,
  fetchEquipmentTypeById,
  createEquipmentType,
} from "./equipmentsActions";

const initialState = {
  equipments: [],
  equipmentTypes: [],
  selectedEquipment: {},
  selectedEquipmentType: {},
  status: {
    equipments: "idle",
    equipmentTypes: "idle",
    selectedEquipment: "idle",
    selectedEquipmentType: "idle",
  }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: {
    equipments: null,
    equipmentTypes: null,
    selectedEquipment: null,
    selectedEquipmentType: null,
  },
};

const equipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchCompanyEquipments, key: "equipments" },
      {
        action: fetchCompanyEquipmentDetail,
        key: "selectedEquipment",
      },
      { action: fetchCompanyEquipmentTypes, key: "equipmentTypes" },
      { action: fetchStudioEquipments, key: "equipments" },
      { action: fetchShowEquipments, key: "equipments" },
      { action: filterShowEquipmentByType, key: "equipments" },
      { action: fetchShowEquipmentDetails, key: "selectedEquipment" },
      {
        action: fetchEquipmentTypeById,
        key: "selectedEquipmentType",
      },
    ]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(createEquipmentType.fulfilled, (state, action) => {
        state.equipmentTypes = [...state.equipmentTypes, action.payload];
        updateStateStatus(state, "equipmentTypes", "succeeded");
      });
  },
});

export const equipmentsStatus = (state) => state.equipments.status;
export const equipmentsError = (state) => state.equipments.error;

export default equipmentsSlice.reducer;
