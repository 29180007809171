// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Connection = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M41.42,18.82c0,12.15-17,27.15-17.32,27.18-.2.02-17.39-15.01-17.39-27.16C6.72,8.76,13.68,1.95,24.11,2c12.09.06,17.32,8.15,17.32,16.82Z" />
      <circle cx="24.07" cy="18.41" r="14" />
      <text
        transform="translate(15.57 24.81)"
        fill="#fff"
        fontFamily="Ubuntu-Medium"
        fontSize={18}
      >
        <tspan x="0" y="0">
          IP
        </tspan>
      </text>
    </svg>
  );
};
