// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../services/axiosInstance';
import { apiCall } from '../utils';

import { fetchShowEquipments } from '../equipments/equipmentsActions';
import { fetchShowScripts } from '../scripts/scriptsActions';

// fetch all shows from a company
export const fetchCompanyShows = createAsyncThunk(
  'shows/fetchCompanyShows',
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`show/all/company/${companyId}/`),
      rejectWithValue
    )
);

// Create a show
export const createShow = createAsyncThunk(
  'shows/createShow',
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`show/`, data), rejectWithValue)
);

// Get Show by Uuid
export const fetchShowById = createAsyncThunk(
  'shows/fetchShowById',
  async ({ showId, companyUuid }, { dispatch, rejectWithValue }) => {
    try {
      // Fetch the show details first
      const show = await apiCall(
        () => axiosInstance.get(`show/${showId}/`),
        rejectWithValue
      );

      // If fetching the show succeeded, dispatch the fetch actions for related data
      if (show) {
        await dispatch(fetchShowEquipments({ showId, companyUuid }));
        await dispatch(fetchShowScripts(showId));

        const [blocks, scenes] = await Promise.all([
          dispatch(fetchShowBlocks(showId)).unwrap(),
          dispatch(fetchShowScenes(showId)).unwrap(),
        ]);
        // Return the show and its details (blocks, scenes)
        return {
          ...show,
          blocks: blocks || [],
          scenes: scenes || [],
        };
      }

      // If the show is not found or any other issue, return empty details
      return {
        blocks: [],
        scenes: [],
      };
    } catch (error) {
      // If any error happens (either fetching the show or any of the related data)
      return rejectWithValue(error);
    }
  }
);

// get show blocks by show uuid
export const fetchShowBlocks = createAsyncThunk(
  'shows/fetchShowBlocks',
  async (showId, { rejectWithValue }) => {
    try {
      const blocks = await apiCall(
        () => axiosInstance.get(`show_block/all/show/${showId}/`),
        rejectWithValue
      );
      if (blocks?.length) {
        // for each block get block_equipment to get the scene thumbnail
        const equipment = await Promise.all(
          blocks.map(async (block) => {
            try {
              const equipments = await axiosInstance.get(
                `show_block/${block?.block_uuid}/equipment/`
              );
              return { ...block, equipments: equipments.data };
            } catch (error) {
              return { ...block, equipments: [] };
            }
          })
        );
        return equipment;
      }
    } catch (error) {
      return rejectWithValue(error || 'failed to fetch block');
    }
  }
);

// Get all scenes from a show
export const fetchShowScenes = createAsyncThunk(
  'shows/fetchShowScenes',
  async (showId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `show_scene/all/${showId}/`
      );
      return response.data;
    } catch (error) {
      // backend sending 404 if no scenes found instead of empty array
      if (error?.response?.status === 404) {
        return [];
      }
      return rejectWithValue(
        error?.response?.data?.detail ||
          error?.response?.data?.detail?.msg ||
          error?.message ||
          'An error occurred'
      );
    }
  }
);

// Add a scene to a show
//! no endpoint
// export const addSceneToShow = createAsyncThunk(
//   "shows/addSceneToShow",
//   async (data, { rejectWithValue }) => {
//     const { selectedScene } = data;
//     return selectedScene;
//     // return apiCall(() => axiosInstance.post(`show_scene/`, data), rejectWithValue)
//   },
// );

// Create a block
export const createBlock = createAsyncThunk(
  'shows/createBlock',
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`show_block/`, data),
      rejectWithValue
    )
);

// get block by id > find block in the show blocks array with uuid
export const fetchBlockById = createAsyncThunk(
  'shows/fetchBlockById',
  async (blockId, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();
      const block = state.shows.show.blocks.find(
        (el) => el.block_uuid === blockId
      );
      if (!block) {
        throw new Error(`Block with ID ${blockId} not found`);
      }
      return block;
    } catch (error) {
      return rejectWithValue(error || 'failed to fetch block');
    }
  }
);

// GET all equipments in a block
export const fetchBlockEquipments = createAsyncThunk(
  'shows/fetchBlockEquipments',
  async (blockId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`show_block/${blockId}/equipment/`),
      rejectWithValue
    )
);

// update a block
// !no endpoint
// export const updateBlock = createAsyncThunk(
//   "shows/updateBlock",
//   async ({ blockId, data }, { rejectWithValue }) =>
//   apiCall(
//     () => axiosInstance.patch(`show_block/update/${blockId}/`, data),
//     rejectWithValue,
//   ),
// );
