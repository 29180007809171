// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchStudioById } from "../../../../store/studio/studioActions";

import { CardLink } from "../../../UI/CardLink/CardLink";

export const StudioNavigation = () => {
  const { studios } = useSelector((state) => state.studios);
  const dispatch = useDispatch();

  const handleClick = (id) => {
    // todo : disconnect websocket connection of current studio
    dispatch(fetchStudioById(id));
  };

  return (
    <>
      {studios.map((studio) => (
        <CardLink
          key={studio.studio_uuid}
          view={false}
          grid={false}
          onClick={() => handleClick(studio.studio_id)}
        >
          <p>{studio.name}</p>
        </CardLink>
      ))}
    </>
  );
};
