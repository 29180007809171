// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Keyword = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <line x1="12.4" y1="42.68" x2="20.33" y2="5.34" />
      <line x1="27.67" y1="42.66" x2="35.6" y2="5.32" />
      <line x1="7.64" y1="16.53" x2="44" y2="16.53" />
      <line x1="4" y1="31.47" x2="40.36" y2="31.47" />
    </svg>
  );
};
