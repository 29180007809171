// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { Navigation } from "../../Navigation/Navigation/Navigation";
import { Footer } from "../../../../web-utils/src/UI/Component/React/V1/Footer/Footer";

import styles from "./RootLayout.module.css";

export const RootLayout = ({ navigation, footer, children }) => {
  return (
    <div className={styles.root_container}>
      <Navigation navigation={navigation} />
      <div className={styles.dashboard}>{children}</div>
      <Footer footer={footer} />
    </div>
  );
};
