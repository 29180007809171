// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const ArrowDown = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="3px"
      stroke={color}
    >
      <polyline points="46 13 24 35 2 13" />
    </svg>
  );
};
