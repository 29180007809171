// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Camera = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="none"
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <path d="M46.1,13.5c-.5-.3-1-.3-1.5,0l-8.4,4.8v-1.2c0-4.2-3.4-7.6-7.6-7.6H4.2c-1.7,0-3,1.4-3,3v18.3c0,4.2,3.4,7.6,7.6,7.6h24.4c1.7,0,3-1.4,3-3v-5.8l8.4,4.8c.7.4,1.7.2,2.1-.6.1-.2.2-.5.2-.8V14.9c0-.5-.3-1-.8-1.3ZM33.1,35.4H8.8c-2.5,0-4.6-2-4.6-4.6V12.6h24.4c2.5,0,4.6,2,4.6,4.6v3.8s0,0,0,0v6s0,0,0,0v8.4s0,0,0,0ZM43.8,30.5l-7.6-4.4v-4.3l7.6-4.4v13Z" />
    </svg>
  );
};
