// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";
import { addAsyncCases, updateStateStatus, clearState } from "../utils";
import {
  fetchCompanies,
  setCompany,
  fetchCompanyMember,
  createCompanyInvitation,
  addUserToCompany,
  createCompany,
  updateCompany,
  updateCompanyOwner,
  removeCompanyUser,
  updateCompanyMemberRole,
  fetchCompanyInvites,
} from "./companiesActions";

const initialState = {
  companies: [],
  company: {
    invites: [],
  },
  companyMember: null,
  status: {
    // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
    companies: "idle",
    company: "idle",
    companyMember: "idle",
  },
  error: { companies: null, company: null, companyMember: null },
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    clearCompanyMember: (state) => {
      state.companyMember = null;
      state.error.companyMember = null;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchCompanies, key: "companies" },
      { action: fetchCompanyMember, key: "companyMember" },
    ]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      // set company: have to add pending, fulfilled, rejected since there is no fetchCompanybyId endpoint so no createAsyncThunk to manage loading and error state
      .addCase(setCompany.pending, (state) => {
        state.status.company = "loading";
      })
      .addCase(setCompany.fulfilled, (state, action) => {
        state.status.company = "succeeded";
        state.company = action.payload;
        state.error.company = null;
      })
      .addCase(setCompany.rejected, (state, action) => {
        state.status.company = "failed";
        state.error.company = action.error.message;
      })
      .addCase(fetchCompanyInvites.fulfilled, (state, action) => {
        state.company.invites = action.payload;
      })
      .addCase(createCompanyInvitation.fulfilled, (state, action) => {
        state.company.invites = [...state.company.invites, action.payload];
      })
      .addCase(addUserToCompany.fulfilled, (state, action) => {
        //  Todo
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.companies = [...state.companies, action.payload];
        updateStateStatus(state, "companies", "succeeded");
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.companies = state.companies.map((c) =>
          c.company_uuid === action.payload.company_uuid ? action.payload : c,
        );
        updateStateStatus(state, "company", "succeeded");
      })
      .addCase(updateCompanyOwner.fulfilled, (state, action) => {
        state.company = action.payload;
        updateStateStatus(state, "company", "succeeded");
      })
      .addCase(removeCompanyUser.fulfilled, (state, action) => {
        // todo
      })
      .addCase(updateCompanyMemberRole.fulfilled, (state, action) => {
        state.companyMember = action.payload;
        updateStateStatus(state, "companyMember", "succeeded");
      });
  },
});

export const { clearCompanyMember } = companiesSlice.actions;

export const companiesStatus = (state) => state.companies.status;
export const companiesError = (state) => state.companies.error;

export default companiesSlice.reducer;
