// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Devicesmanage = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <line x1="2" y1="24.09" x2="46" y2="23.91" />
      <rect x="6.88" y="35.09" width="10" height="9" rx="3.16" ry="3.16" />
      <line x1="11.87" y1="24.04" x2="11.87" y2="35.03" />
      <rect x="31" y="35.09" width="10" height="9" rx="3.16" ry="3.16" />
      <line x1="35.99" y1="24.04" x2="35.99" y2="35.03" />
      <rect x="19" y="3.96" width="10" height="9" rx="3.16" ry="3.16" />
      <line x1="23.98" y1="24.01" x2="23.98" y2="13.02" />
    </svg>
  );
};
