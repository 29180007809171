// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const UserConfiguration = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
    >
      <circle cx="13.87" cy="14.24" r="6.35" strokeWidth="2px" />
      <path
        d="M2.5,26.56c0,4.93,4.93,10.89,10.89,10.89s4.07-.76,5.68-1.89c-2.08-2.46-3.31-5.59-3.31-8.43s1.89-2.27,4.64-2.84c-6.25-1.33-17.9-.57-17.9,2.27Z"
        strokeWidth="2px"
      />
      <circle cx="30.06" cy="11.59" r="8.05" strokeWidth="2px" />
      <path
        d="M43.13,28.45c0-.47.09-.95.09-1.42,0-1.99-4.64-3.22-10.13-3.6-4.36-.28-9.28,0-12.69.76-2.75.57-4.64,1.52-4.64,2.84,0,2.84,1.23,5.97,3.31,8.43,2.27,2.75,5.49,4.83,9.19,5.21h0"
        strokeWidth="2px"
      />
      <path
        d="M43.32,34.42v1.42c0,.47.47.95,1.04,1.04l1.14.19c-.28,1.23-.76,2.37-1.42,3.31l-1.04-.66c-.47-.28-1.04-.28-1.42,0l-.95.95c-.38.38-.38.95,0,1.42l.66.95c-.95.66-2.08,1.14-3.31,1.42l-.19-1.23c-.09-.57-.57-1.04-1.04-1.04h-1.42c-.47,0-.95.38-1.04.95l-.28,1.23c-1.23-.28-2.37-.76-3.41-1.42l.76-1.04c.38-.47.38-1.14,0-1.42l-.95-.95c-.38-.28-.95-.38-1.42,0l-1.04.76c-.66-1.04-1.14-2.18-1.42-3.41l1.23-.19c.57-.09,1.04-.57,1.04-1.04v-1.42c0-.47-.38-.85-.95-1.04l-1.33-.28c.28-1.23.76-2.37,1.42-3.41l1.04.76c.47.38,1.14.38,1.42,0l.95-.95c.38-.38.38-.95,0-1.42l-.76-1.04c1.04-.66,2.18-1.14,3.41-1.42l.28,1.23c.09.57.57.95,1.04.95h1.42c.47,0,.95-.47,1.04-1.04l.19-1.14c1.23.28,2.37.76,3.31,1.42l-.66,1.04c-.28.47-.28,1.04,0,1.42l.95.95c.38.38.95.38,1.42,0l.95-.66c.66,1.04,1.14,2.18,1.42,3.41h0l-1.23.28c-.57.09-.95.57-.95,1.04h0l.09.09Z"
        strokeWidth="1px"
      />
      <circle cx="36.03" cy="35.08" r="2.5" strokeWidth="px" />
    </svg>
  );
};
