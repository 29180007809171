// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { useOSContext } from "../../../../../context/OSContext";

import styles from "./Footer.module.css";

export const Footer = ({ footer }) => {
  const { version } = useOSContext();
  return (
    <footer className={styles.footer_container}>
      <div className={styles.footer_wrapper}>
        <div>{footer}</div>
        <div>
          <span>
            {version} | 2025 &#169; Mo-Sys Engineering Ltd. All rights reserved
          </span>
        </div>
      </div>
    </footer>
  );
};
