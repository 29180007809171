// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Delay = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="24.3" cy="4.51" r="2.42" />
      <line x1="19.98" y1="19.73" x2="7.98" y2="6.35" />
      <path
        d="M4.54,18.66c-3.44,12.53,3.78,21.6,11.9,25.19,8.69,3.85,16.6-.19,17.41-.63,6.52-3.48,10.77-10.48,10.8-18.19.04-11.14-9.58-21.19-20.61-20.49"
        strokeLinecap="round"
      />
    </svg>
  );
};
