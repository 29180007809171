// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import styles from "./Typography.module.css";

export const Typography = ({ variant, children, className, ...props }) => {
  const getClassName = () => {
    switch (variant) {
      case "h1":
        return styles.heading1;
      case "h2":
        return styles.heading2;
      case "h3":
        return styles.heading3;
      case "p":
        return styles.paragraph;
      default:
        return styles.custom;
    }
  };

  return (
    <div className={`${getClassName()} ${className}`} {...props}>
      {children}
    </div>
  );
};
