// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Card = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <rect x="5" y="4" width="16" height="16" rx="4.8" ry="4.8" />
      <rect x="28" y="4" width="16" height="16" rx="4.8" ry="4.8" />
      <rect x="5" y="27" width="16" height="16" rx="4.8" ry="4.8" />
      <rect x="28" y="27" width="16" height="16" rx="4.8" ry="4.8" />
    </svg>
  );
};
