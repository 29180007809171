// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from 'react';
import { NavLink } from 'react-router';

import { Icons } from '../Icons/Icons';

import styles from './link.module.css';

export const LinkComponent = (props) => {
  const {
    variant,
    activeColor = 'var(--color-primary)',
    iconColor,
    href,
    sidebar,
    externalLink,
    onClick,
    children,
    ...rest
  } = props;

  return (
    <NavLink
      to={href}
      onClick={onClick}
      className={styles.link}
      target={externalLink ? '_blank' : '_self'}
      rel={externalLink ? 'noopener noreferrer' : undefined}
      aria-label={
        externalLink ? `External link to ${href}` : undefined
      }
      data-testid="link"
      style={({ isActive }) => ({
        backgroundColor: variant
          ? `var(--color-${variant})`
          : 'transparent',
        borderLeft:
          isActive && sidebar ? `2px solid ${activeColor}` : 'none',
        borderBottom:
          isActive && !sidebar ? `2px solid ${activeColor}` : 'none',
        color: isActive && activeColor,
      })}
      end
    >
      {({ isActive }) => {
        const Iconcolor = isActive ? activeColor : iconColor;
        return (
          <>
            <span
              className={`${sidebar ? styles.navbar_link : styles.link_item}`}
            >
              <Icons iconColor={Iconcolor} sizing {...rest} />
              {children}
            </span>
            {externalLink && (
              <Icons
                iconType="linkExternal"
                iconSize={20}
                iconColor={Iconcolor}
              />
            )}
          </>
        );
      }}
    </NavLink>
  );
};
