// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// Get all Script from a show
export const fetchShowScripts = createAsyncThunk(
  "shows/fetchShowScripts",
  async (showId, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get(`script/${showId}/`), rejectWithValue),
);

// create script line
//! no endpoint
// export const createScriptLine = createAsyncThunk(
//   "shows/createScriptLine",
//   async ({ showId, data }, { rejectWithValue }) =>
//     apiCall(
//       () => axiosInstance.post(`script/${showId}/line/`, data),
//       rejectWithValue,
//     ),
// );

// update script line
//! no endpoint
// export const updateScriptLine = createAsyncThunk(
//   "shows/updateScriptLine",
//   async ({ showId, lineId, data }, { rejectWithValue }) =>
//     apiCall(
//       () =>
//         axiosInstance.patch(`script/${showId}/line/update/${lineId}/`, data),
//       rejectWithValue,
//     ),
// );
