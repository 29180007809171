// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const CameraRound = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <rect x="7" y="14.48" width="26.87" height="19.03" rx="3.49" ry="3.49" />
      <path d="M41,18.07v9.35c0,.24-.24.41-.49.38l-6.64-1.15v-7.8l6.64-1.15c.26-.03.49.15.49.38Z" />
    </svg>
  );
};
