// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Plates = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="none"
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <path d="M2.1,12.2l21.3,11.6c.4.2.8.2,1.2,0l21.3-11.6c.6-.3.8-.9.5-1.5-.1-.3-.4-.5-.6-.6L24.5,1.5c-.3-.1-.7-.1-1,0L2.2,10.2c-.6.2-.9.8-.7,1.4.1.3.3.5.6.6h0ZM45.7,20.6l-4.6-2-16.6,9c-.4.2-.8.2-1.2,0L6.7,18.7l-4.6,2c-.6.3-.8,1-.5,1.6.1.2.3.4.5.5l21.3,12.3c.4.2.8.2,1.2,0l21.3-12.3c.6-.4.7-1.1.4-1.6-.2-.2-.4-.4-.6-.5h0ZM45.7,31.8l-3.9-1.7-17.3,9.4c-.4.2-.8.2-1.2,0L6,30.1l-3.9,1.7c-.6.3-.8,1-.5,1.6.1.2.3.4.5.5l21.3,12.5c.4.2.8.2,1.2,0l21.3-12.5c.6-.4.7-1.1.4-1.7-.2-.2-.4-.4-.6-.5Z" />
    </svg>
  );
};
