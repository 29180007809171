// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, { useState } from "react";

import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";

import styles from "./CardLink.module.css";

export const CardLink = ({
  onClick,
  view = true,
  grid = true,
  isActive = false,
  children,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`${styles.container} ${isHovered || isActive ? styles.hover : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div className={`${grid ? styles.grid : styles.flex}`}>{children}</div>
      {view && (
        <div className={styles.action}>
          <Icons iconType="linkExternal" iconSize={20} />
        </div>
      )}
    </div>
  );
};
