// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Filter = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <line x1="4.5" y1="8.5" x2="43.5" y2="8.5" />
      <line x1="4.5" y1="23.75" x2="31.5" y2="23.75" />
      <line x1="4.5" y1="39.5" x2="21.5" y2="39.5" />
    </svg>
  );
};
