// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from 'react';

import { Icons } from '../Icons/Icons';

import styles from './Button.module.css';

export const Button = ({
  type = 'button',
  color = 'primary',
  size = 'sm',
  round = false,
  onClick,
  onDbleClick,
  classname,
  children,
  ...props
}) => {
  const { disabled } = props;
  return (
    <button
      type={type}
      className={`${styles.mo_sys_button} ${styles[color]} ${styles[size]} ${round && styles.round} ${classname}`}
      disabled={disabled}
      onClick={onClick}
      onDoubleClick={onDbleClick}
    >
      <Icons {...props} />
      {children}
    </button>
  );
};
