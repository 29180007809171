// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Ptz = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <circle cx="24" cy="17.85" r="16" />
      <circle cx="24" cy="17.85" r="12" />
      <path d="M21.46,14.76s.05-.04.07-.06c.15-.11.3-.22.46-.31" />
      <path d="M26.39,21.06c-.67.5-1.49.79-2.39.79-2.21,0-4-1.79-4-4,0-.57.12-1.11.33-1.6" />
      <path d="M23.38,13.9c.2-.03.41-.05.62-.05,2.21,0,4,1.79,4,4,0,.43-.07.85-.2,1.24" />
      <path d="M7.99,35.32h32v9.04c0,1.12-.91,2.03-2.03,2.03H9.9c-1.05,0-1.9-.85-1.9-1.9v-9.17h0Z" />
      <line x1="10.32" y1="41.43" x2="29.43" y2="41.46" />
      <line x1="32.84" y1="41.39" x2="37.46" y2="41.39" />
    </svg>
  );
};
