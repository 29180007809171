// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ReactFlowProvider } from '@xyflow/react'; // ReactFlow : React component for building node-based editors and interactive diagrams

import { OSContextProvider } from '../web-utils/src/context/OSContext';
import { AuthProvider } from '../web-utils/src/context/authContext';
import { ThemeContextProvider } from './context/themeContext';
import { WebSocketProvider } from './context/websocketContext/websocketContext';
import { store, persistor } from './store/store';

import App from './App';

import '@xyflow/react/dist/style.css';

import './style/app.module.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <OSContextProvider>
      <ThemeContextProvider>
        <AuthProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <WebSocketProvider>
                <ReactFlowProvider>
                  <App />
                </ReactFlowProvider>
              </WebSocketProvider>
            </PersistGate>
          </Provider>
        </AuthProvider>
      </ThemeContextProvider>
    </OSContextProvider>
  </React.StrictMode>
);
