// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const LinkOff = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path
        d="M17.71,12.53h16.3c6.62,0,12,5.37,12,12,0,3.31-1.35,6.31-3.52,8.48-.72.72-1.53,1.35-2.42,1.88"
        strokeLinecap="round"
      />
      <path d="M12.15,12.67c-2.56.4-4.87,1.61-6.63,3.37-2.17,2.18-3.51,5.18-3.51,8.49,0,6.62,5.37,12,12,12h20" />
      <line x1="34" y1="24.53" x2="29.71" y2="24.53" />
      <line x1="24.01" y1="24.53" x2="14.01" y2="24.53" />
      <line x1="3.01" y1="3.53" x2="45.01" y2="45.53" strokeLinecap="round" />
    </svg>
  );
};
