// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

import { fetchCompanyKeywords } from "../keywords/keywordsActions";
import { fetchCompanyProviders } from "../providers/providersActions";
import { fetchCompanyStudios } from "../studios/studiosActions";
import { fetchCompanyEquipments } from "../equipments/equipmentsActions";

// get companies
export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("company/all/"), rejectWithValue),
);

// Set Company and Fetch Related Data
export const setCompany = createAsyncThunk(
  "company/setCompanyAndFetchUsers",
  async (companyId, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState();
      const company = state.companies.companies.find(
        (c) => c.company_uuid === companyId,
      );
      if (!company) {
        throw new Error(`Company with ID ${companyId} not found`);
      }
      await dispatch(fetchCompanyProviders(companyId));
      await dispatch(fetchCompanyStudios(companyId));
      dispatch(fetchCompanyInvites(companyId));
      dispatch(fetchCompanyKeywords(companyId));
      dispatch(fetchCompanyEquipments(companyId));

      return company;
    } catch (error) {
      return rejectWithValue(error.message || "Unknown error");
    }
  },
);

// get company/member
export const fetchCompanyMember = createAsyncThunk(
  "company/companyMember",
  async (companyMemberId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`company/member/${companyMemberId}/`),
      rejectWithValue,
    ),
);

// add user to company
//! returns a 500
// TODO: state management update company state // need to test with another account
export const addUserToCompany = createAsyncThunk(
  "company/addUserToCompany",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post("company/add/user/", data),
      rejectWithValue,
    ),
);

// create company
export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`company/create/`, data), rejectWithValue),
);

// update company
export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.patch(`company/update/`, data),
      rejectWithValue,
    ),
);

// update company owner
export const updateCompanyOwner = createAsyncThunk(
  "company/updateCompanyOwner",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.patch(`company/update/owner/`, data),
      rejectWithValue,
    ),
);

// delete user from company
//! returns a 500
// TODO: state mananagement once a endpoint company/companyId is added do state management to remove user from array of company_members
export const removeCompanyUser = createAsyncThunk(
  "company/removeCompanyUser",
  async ({ companyId, userId }, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.delete(`company/${companyId}/remove/${userId}/`),
      rejectWithValue,
    ),
);

// update company member role
export const updateCompanyMemberRole = createAsyncThunk(
  "company/updateMemberRole",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.patch(`company/member/update/`, data),
      rejectWithValue,
    ),
);

// get company invites
export const fetchCompanyInvites = createAsyncThunk(
  "company/companyInvites",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`company/${companyId}/invites/`),
      rejectWithValue,
    ),
);

//  create company member invite
export const createCompanyInvitation = createAsyncThunk(
  "company/createCompanyInvitation",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`company/member/invite/`, data),
      rejectWithValue,
    ),
);
