// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Warning = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M24,5.24l20.34,38.69H3.66S24,5.24,24,5.24M24,3.1L2,44.94h44S24,3.1,24,3.1h0Z" />
      <line x1="24" y1="19.3" x2="24" y2="32.54" strokeLinecap="round" />
      <circle cx="24" cy="37.68" r=".5" strokeLinecap="round" />
    </svg>
  );
};
