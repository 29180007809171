// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from 'react';

export const Notification = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
    >
      <path d="M5.02,32.58c2.9,1.2,7.07,2.6,12.24,3.3,1.14.15,3.67.45,6.78.44,3.89-.02,10.42-.54,18.34-3.65-1.35-1.39-3.16-3.64-4.24-6.79-1.24-3.6-.29-6.65-1.27-10.78-.59-2.5-1.74-5.53-6.03-8.49-3.43-2.37-7.08-2.25-7.81-2.21-.63.03-4.79.31-8.15,3.57-2.93,2.83-3.72,4.91-4.24,7.56-.78,3.92.14,6.4-.68,9.42-.98,3.62-3.25,6.13-4.92,7.64Z" />
      <path d="M17.5,36.32c-.06.26-.83,4.09,1.87,6.71,1.75,1.69,3.88,1.85,4.33,1.87,3.07.15,5.04-1.88,5.35-2.21,2.42-2.57,1.85-5.92,1.78-6.28" />
    </svg>
  );
};
