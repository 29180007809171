// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import { addAsyncCases, updateStateStatus, clearState } from "../utils";

import {
  fetchCompanyKeywords,
  createKeyword,
  deleteKeyword,
} from "./keywordsActions";

const initialState = {
  keywords: [],
  status: { keywords: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { keywords: null },
};

const keywordsSlice = createSlice({
  name: "keywords",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [{ action: fetchCompanyKeywords, key: "keywords" }]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(createKeyword.fulfilled, (state, action) => {
        state.keywords = [...state.keywords, action.payload];
        updateStateStatus(state, "keywords", "succeeded");
      })
      .addCase(deleteKeyword.fulfilled, (state, action) => {
        state.keywords = state.keywords.filter(
          (k) => k.keyword_id !== action.payload,
        );
        updateStateStatus(state, "keywords", "succeeded");
      });
  },
});

export const keywordsStatus = (state) => state.keywords.status;
export const keywordsError = (state) => state.keywords.error;

export default keywordsSlice.reducer;
