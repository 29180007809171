// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { lazy, Suspense } from "react";
import { RouterProvider, createHashRouter, Outlet } from "react-router";
import { Root, Error } from "./pages/Root/Root";
import { ProtectedRoute } from "../web-utils/src/routes/ProtectedRoute";
import { Loader } from "../web-utils/src/UI/Component/React/V1/Loader/Loader";
// Protected company route
import ProtectedCompanyRoute from "./pages/ProtectedCompanyRoute";

// Lazy-loaded pages
const LoginPage = lazy(() => import("./pages/Login/Login"));

const Register = lazy(() => import("./pages/Register/Register"));

// Show routes
const ShowList = lazy(() => import("./pages/ShowList/ShowList"));
const Show = lazy(() => import("./pages/Show/Show"));
const LaunchShow = lazy(() => import("./pages/LaunchShow/LaunchShow"));

// Block routes
const BlockById = lazy(() => import("./pages/BlockById/BlockById"));
const BlockManager = lazy(() => import("./pages/BlockManager/BlockManager"));
const BlockScenes = lazy(() => import("./pages/BlockScenes/BlockScenes"));
const BlockEquipment = lazy(
  () => import("./pages/BlockEquipment/BlockEquipment"),
);
const MediaLibrary = lazy(() => import("./pages/MediaLibrary/MediaLibrary"));
const Dialogue = lazy(() => import("./pages/Dialogue/Dialogue"));
const Graphics = lazy(() => import("./pages/Graphics/Graphics"));
const OverlayTypes = lazy(() => import("./pages/OverlayTypes/OverlayTypes"));
const OverlayGraphics = lazy(
  () => import("./pages/OverlayGraphics/OverlayGraphics"),
);
const Asset2D = lazy(() => import("./pages/Asset2D/Asset2D"));

// Admin routes
const Companies = lazy(() => import("./pages/Companies/Companies"));
const Company = lazy(() => import("./pages/Company/Company"));

const CompanyMember = lazy(() => import("./pages/CompanyMember/CompanyMember"));
const Provider = lazy(() => import("./pages/Provider/Provider"));
const Studios = lazy(() => import("./pages/Studios/Studios"));
const Studio = lazy(() => import("./pages/Studio/Studio"));

// Asset Library routes
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Scenes = lazy(() => import("./pages/ScenesAll/ScenesAll"));
const ScenesByType = lazy(() => import("./pages/SceneByType/SceneByType"));
const SceneByStatus = lazy(() => import("./pages/SceneByStatus/SceneByStatus"));

// Fallback Loader for lazy-loaded components
const SuspenseFallback = ({ children }) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

const configureRoutes = () =>
  // TODO: change createHashRouter for createBrowserRouter >>  needs config on route handling in AWS S3bucket/cloudFront
  createHashRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoute>
              <SuspenseFallback>
                <ProtectedCompanyRoute />
              </SuspenseFallback>
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: (
                <SuspenseFallback>
                  <ShowList />
                </SuspenseFallback>
              ),
            },
            {
              path: ":showId",
              element: (
                <SuspenseFallback>
                  <Show />
                </SuspenseFallback>
              ),
              children: [
                {
                  path: ":blockId",
                  element: (
                    <SuspenseFallback>
                      <BlockById />
                    </SuspenseFallback>
                  ),
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseFallback>
                          <BlockManager />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "scenes",
                      element: (
                        <SuspenseFallback>
                          <BlockScenes />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "equipment",
                      element: (
                        <SuspenseFallback>
                          <BlockEquipment />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "media",
                      element: (
                        <SuspenseFallback>
                          <MediaLibrary />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "dialogue",
                      element: (
                        <SuspenseFallback>
                          <Dialogue />
                        </SuspenseFallback>
                      ),
                    },

                    {
                      path: "graphics",
                      element: (
                        <SuspenseFallback>
                          <Graphics />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "overlay",
                      element: <Outlet />,
                      children: [
                        {
                          index: true,
                          element: (
                            <SuspenseFallback>
                              <OverlayTypes />
                            </SuspenseFallback>
                          ),
                        },
                        {
                          path: "graphic",
                          element: (
                            <SuspenseFallback>
                              <OverlayGraphics />
                            </SuspenseFallback>
                          ),
                        },
                        {
                          path: "2d",
                          element: (
                            <SuspenseFallback>
                              <Asset2D />
                            </SuspenseFallback>
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "launch",
                  element: (
                    <SuspenseFallback>
                      <LaunchShow />
                    </SuspenseFallback>
                  ),
                },
              ],
            },

            {
              path: "admin",
              element: <Outlet />,
              children: [
                {
                  path: "companies",
                  element: (
                    <SuspenseFallback>
                      <Companies />
                    </SuspenseFallback>
                  ),
                },
                {
                  path: "company/:companyId",
                  element: (
                    <SuspenseFallback>
                      <Company />
                    </SuspenseFallback>
                  ),
                  children: [
                    {
                      path: "member/:companyMemberId",
                      element: (
                        <SuspenseFallback>
                          <CompanyMember />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "studio/:studioId",
                      element: (
                        <SuspenseFallback>
                          <Studio />
                        </SuspenseFallback>
                      ),
                    },
                    {
                      path: "provider/:providerId",
                      element: (
                        <SuspenseFallback>
                          <Provider />
                        </SuspenseFallback>
                      ),
                    },
                  ],
                },
                {
                  path: "studios",
                  element: (
                    <SuspenseFallback>
                      <Studios />
                    </SuspenseFallback>
                  ),
                  children: [
                    {
                      path: ":studioId",
                      element: (
                        <SuspenseFallback>
                          <Studio />
                        </SuspenseFallback>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: "library",
              element: <Dashboard />,
              children: [
                {
                  index: true,
                  element: (
                    <SuspenseFallback>
                      <Scenes />
                    </SuspenseFallback>
                  ),
                },
                {
                  path: "provider/:sceneType",
                  element: (
                    <Suspense>
                      <ScenesByType />
                    </Suspense>
                  ),
                },
                {
                  path: "scene/:sceneStatus",
                  element: (
                    <Suspense>
                      <SceneByStatus />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "login",
          element: (
            <SuspenseFallback>
              <LoginPage />
            </SuspenseFallback>
          ),
        },
        {
          path: "/register",
          element: (
            <SuspenseFallback>
              <Register />
            </SuspenseFallback>
          ),
        },
        {
          path: "live",
          element: <div>live video page</div>,
        },
      ],
    },
  ]);

function App() {
  const router = configureRoutes();

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
}

export default App;
