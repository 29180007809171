// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Script = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M43.53,10.97v19.4c0,1.58-1.28,2.86-2.86,2.86h-11.8c-.35,0-.68.18-.86.48l-3.59,5.95c-.2.32-.66.32-.86,0l-3.59-5.95c-.18-.3-.51-.48-.86-.48H7.33c-1.58,0-2.86-1.28-2.86-2.86V10.97c0-1.58,1.28-2.86,2.86-2.86h33.34c1.58,0,2.86,1.28,2.86,2.86Z" />
      <line x1="11.02" y1="20.11" x2="37" y2="20.11" />
      <line x1="11" y1="26.45" x2="25.5" y2="26.45" />
      <line x1="11.02" y1="13.76" x2="37" y2="13.76" />
    </svg>
  );
};
