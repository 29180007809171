// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const MinusForm = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill="none"
      stroke={color}
    >
      <rect
        x="1.5"
        y="1.5"
        width="29"
        height="29"
        rx="2.81"
        ry="2.81"
        strokeWidth="1px"
      />
      <line x1="8.8" y1="15.98" x2="23.19" y2="15.98" strokeWidth="2px" />
    </svg>
  );
};
