// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Data = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <line x1="2" y1="11.5" x2="44.92" y2="11.5" />
      <polygon points="41.6 15.59 40.92 14.86 44.53 11.5 40.92 8.14 41.6 7.41 46 11.5 41.6 15.59" />
      <line x1="46.14" y1="36.5" x2="3.23" y2="36.5" />
      <polygon points="6.54 32.41 7.22 33.14 3.61 36.5 7.22 39.86 6.54 40.59 2.14 36.5 6.54 32.41" />
    </svg>
  );
};
