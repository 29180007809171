// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const View = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
 <path d="M24,18c3.31,0,6,2.69,6,6s-2.69,6-6,6-6-2.69-6-6,2.69-6,6-6M24,17c-3.87,0-7,3.13-7,7s3.13,7,7,7,7-3.13,7-7-3.13-7-7-7h0Z" />
 <path d="M46,24c0,1.78-9.85,12.33-22,12.33S2,25.86,2,24c0-1.52,9.85-12.33,22-12.33s22,10.74,22,12.33Z" />
    </svg>
  );
};
