import React from "react";

export const Positioning = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="none"
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <path d="M32.7,39.4l-7.2,7.2c-.9.9-2.2.9-3.1,0l-7.2-7.2c-1.4-1.4-.4-3.7,1.5-3.7h4.6v-9.1h-9.1v4.6c0,1.9-2.3,2.9-3.7,1.5l-7.2-7.2c-.9-.9-.9-2.2,0-3.1l7.2-7.2c1.4-1.4,3.7-.4,3.7,1.5v4.6h9.1v-9.1h-4.6c-1.9,0-2.9-2.3-1.5-3.7l7.2-7.2c.9-.9,2.2-.9,3.1,0l7.2,7.2c1.4,1.4.4,3.7-1.5,3.7h-4.6v9.1h9.1v-4.6c0-1.9,2.3-2.9,3.7-1.5l7.2,7.2c.9.9.9,2.2,0,3.1l-7.2,7.2c-1.4,1.4-3.7.4-3.7-1.5v-4.6h-9.1v9.1h4.6c1.9,0,2.9,2.3,1.5,3.7h0Z" />
    </svg>
  );
};
