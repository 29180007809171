// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import {
  addAsyncCases,
  //  updateStateStatus,
  clearState,
} from "../utils";

import { fetchShowScripts } from "./scriptsActions";

const initialState = {
  scripts: [],
  status: { scripts: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { scripts: null },
};

const scriptsSlice = createSlice({
  name: "scripts",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [{ action: fetchShowScripts, key: "scripts" }]);
    builder.addCase(clearState, () => initialState); // Reset state on logout
  },
});

export const scriptsStatus = (state) => state.scripts.status;
export const scriptsError = (state) => state.scripts.error;

export default scriptsSlice.reducer;
