// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const DownLoad = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path
        d="M45.65,26.21v13.08c0,.93-1.34,1.68-3,1.68H5.35c-1.66,0-3-.75-3-1.68v-13.08"
        strokeLinecap="round"
      />
      <line x1="24" y1="7.7" x2="24" y2="28.02" strokeLinecap="round" />
      <polygon points="19.91 24.7 20.64 24.02 24 27.63 27.36 24.02 28.09 24.7 24 29.1 19.91 24.7" />
    </svg>
  );
};
