// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const UsersEmail = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="15" cy="16.14" r="6.35" />
      <path d="M3.64,28.45c0,4.93,4.93,10.89,10.89,10.89s4.07-.76,5.68-1.89c-2.08-2.46-3.31-5.59-3.31-8.43s1.89-2.27,4.64-2.84c-6.25-1.33-17.9-.57-17.9,2.27Z" />
      <circle cx="31.2" cy="13.49" r="8.05" />
      <path d="M44.36,28.93c0-1.99-4.64-3.22-10.13-3.6-4.36-.28-9.28,0-12.69.76-2.75.57-4.64,1.52-4.64,2.84,0,2.84,1.23,5.97,3.31,8.43,2.27,2.75,5.49,4.83,9.19,5.21h0" />
      <rect
        x="25.6"
        y="28.71"
        width="19.1"
        height="13.85"
        rx="1.54"
        ry="1.54"
      />
      <polyline points="44.62 29.78 35.15 36.12 25.67 29.78" />
    </svg>
  );
};
