// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Center = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M24,17c3.86,0,7,3.14,7,7s-3.14,7-7,7-7-3.14-7-7,3.14-7,7-7M24,16c-4.42,0-8,3.58-8,8s3.58,8,8,8,8-3.58,8-8-3.58-8-8-8h0Z" />
      <path d="M32.5,37.5l-6.38,6.38c-1.17,1.17-3.07,1.17-4.24,0l-6.38-6.38" />
      <path d="M37,15l6.88,6.88c1.17,1.17,1.17,3.07,0,4.24l-6.88,6.88" />
      <path d="M14.5,11.5l7.38-7.38c1.17-1.17,3.07-1.17,4.24,0l7.38,7.38" />
      <path d="M11,33l-6.88-6.88c-1.17-1.17-1.17-3.07,0-4.24l6.88-6.88" />
    </svg>
  );
};
