// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useEffect } from "react";
import { Outlet, useRouteError, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "../../../web-utils/src/context/authContext";

import { fetchUser } from "../../store/user/userActions";

import {
  fetchCompanies,
  setCompany,
} from "../../store/companies/companiesActions";

import { fetchScenes } from "../../store/scenes/scenesActions";
import { clearSelectedScene } from "../../store/scenes/scenesSlice";

import { RootLayout } from "../../components/UI/RootLayout/RootLayout";
import { Navigation } from "../../components/Navigation/Navigation/Navigation";
import { Footer } from "../../components/Navigation/Footer/Footer";

export const Root = () => {
  const { accessToken } = useAuth();
  const { user } = useSelector((state) => state.user);
  const { companies, company } = useSelector((state) => state.companies);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUser());
      dispatch(fetchCompanies());
      dispatch(fetchScenes());
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    // set company to default first company in companies array
    if (companies.length === 1 && !company?.company_uuid) {
      const companyId = companies[0]?.company_uuid;
      dispatch(setCompany(companyId));
    }
  }, [companies, company?.company_uuid, dispatch]);

  useEffect(() => {
    dispatch(clearSelectedScene());
  }, [location, dispatch]);

  return (
    <RootLayout
      navigation={user?.user_id && accessToken && <Navigation />}
      footer={<Footer />}
    >
      <Outlet />
    </RootLayout>
  );
};

// TODO add styling
export const Error = () => {
  const error = useRouteError();
  return (
    <div>
      <h1>Sorry, an unexpected error has occurred.</h1>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
