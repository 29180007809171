// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// get Studios
export const fetchStudios = createAsyncThunk(
  "studios/fetchStudios",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("studio/all/"), rejectWithValue),
);

// get company studios
export const fetchCompanyStudios = createAsyncThunk(
  "studios/fetchCompanyStudio",
  async (companyId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.get(`studio/company-studios/${companyId}`),
      rejectWithValue,
    ),
);

// create studio
export const createStudio = createAsyncThunk(
  "studios/createStudio",
  async (data, { rejectWithValue }) =>
    apiCall(() => axiosInstance.post(`studio/`, data), rejectWithValue),
);

//  update studio
export const updateStudio = createAsyncThunk(
  "studios/updateStudio",
  async (data, { dispatch, rejectWithValue }) =>
    apiCall(() => axiosInstance.patch(`studio/update/`, data), rejectWithValue),
);

// Delete studio
export const deleteStudio = createAsyncThunk(
  "studios/deleteStudio",
  async (studioId, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.delete(`studio/delete/${studioId}`),
      rejectWithValue,
    ),
);

// Add member to studio
// TODO : state mamnagement once studio membership is returned
export const addMemberStudio = createAsyncThunk(
  "studios/addMemberStudio",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`studio/add/member/`, data),
      rejectWithValue,
    ),
);
