// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";
import { addAsyncCases, clearState } from "../utils";

import {
  fetchUser,
  fetchUserInvites,
  fetchCompanyUsers,
  acceptCompanyInvitation,
} from "./userActions";

const initialState = {
  user: { invites: [] },
  users: [],
  status: { user: "idle", users: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { user: null, users: null },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchUser, key: "user" },
      { action: fetchCompanyUsers, key: "users" },
    ]);

    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(fetchUserInvites.fulfilled, (state, action) => {
        state.user.invites = action.payload;
      })
      .addCase(acceptCompanyInvitation.fulfilled, (state, action) => {
        // TODO  Handle accept invitation if needed
      });
  },
});

export const { clearUser } = userSlice.actions;

export const userStatus = (state) => state.user.status;
export const userError = (state) => state.user.error;

export default userSlice.reducer;
