// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, { useMemo } from "react";

import { Badge } from "../../../../web-utils/src/UI/Component/React/V1/Badge/Badge";

// Possible company role: admin, controller, operator

const getColor = (role) => {
  switch (role) {
    case "admin":
      return "error";
    case "controller":
      return "info";
    case "operator":
      return "success";
    default:
      return "";
  }
};

export const UserRole = ({ role }) => {
  const color = useMemo(() => getColor(role), [role]);

  return <Badge outline variant={color} status={role} />;
};
