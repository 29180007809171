// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";
import { addAsyncCases, updateStateStatus, clearState } from "../utils";
import {
  fetchStudios,
  fetchCompanyStudios,
  createStudio,
  updateStudio,
  deleteStudio,
  addMemberStudio,
} from "./studiosActions";

const initialState = {
  studios: [],
  status: { studios: "idle" }, // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
  error: { studios: null },
};

const studiosSlice = createSlice({
  name: "studios",
  initialState,
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchStudios, key: "studios" },
      { action: fetchCompanyStudios, key: "studios" },
    ]);

    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(createStudio.fulfilled, (state, action) => {
        state.studios = [...state.studios, action.payload];
        updateStateStatus(state, "studios", "succeeded");
      })
      .addCase(updateStudio.fulfilled, (state, action) => {
        state.studios = state.studios.map((studio) =>
          studio.studio_id === action.payload.studio_id
            ? { ...studio, ...action.payload }
            : studio,
        );
        updateStateStatus(state, "studios", "succeeded");
      })
      .addCase(deleteStudio.fulfilled, (state, action) => {
        state.studios = state.studios.filter(
          (studio) => studio.studio_id !== action.payload.studio_id,
        );
        updateStateStatus(state, "studios", "succeeded");
      })
      .addCase(addMemberStudio.fulfilled, (state, action) => {
        // TODO
      });
  },
});

export const studiosStatus = (state) => state.studios.status;
export const studiosError = (state) => state.studios.error;

export default studiosSlice.reducer;
