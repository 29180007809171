// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";
import { addAsyncCases, updateStateStatus, clearState } from "../utils";

import {
  fetchCompanyShows,
  createShow,
  fetchShowById,
  fetchBlockById,
  fetchShowBlocks,
  fetchShowScenes,
  createBlock,
  fetchBlockEquipments,
  // addSceneToShow,//! no endpoint yet
  // updateBlock,//! no endpoint yet
} from "./showsAction";

const initialState = {
  shows: [],
  show: {
    blocks: [],
    scenes: [],
  },
  block: {
    equipments: [],
  },
  status: { shows: "idle", show: "idle", block: "idle" },
  error: { shows: null, show: null, block: null },
};

const showsSlice = createSlice({
  name: "shows",
  initialState,
  reducers: {
    clearShow: (state) => {
      state.show = {
        blocks: [],
        scenes: [],
      };
      state.block = {
        equipments: [],
      };
      state.status.show = "idle";
      state.status.block = "idle";
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, [{ action: fetchCompanyShows, key: "shows" }]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(createShow.fulfilled, (state, action) => {
        state.show = action.payload;
        updateStateStatus(state, "shows", "succeeded");
      })
      .addCase(fetchShowById.pending, (state, action) => {
        state.status.show = "loading";
        updateStateStatus(state, "show", "loading");
      })
      .addCase(fetchShowById.fulfilled, (state, action) => {
        state.show = { ...state.block, ...action.payload };
        updateStateStatus(state, "show", "succeeded");
      })
      .addCase(fetchShowById.rejected, (state, action) => {
        state.error.show = action.payload;
        updateStateStatus(state, "show", "failed", action);
      })
      .addCase(fetchShowBlocks.fulfilled, (state, action) => {
        state.show.blocks = action.payload;
        updateStateStatus(state, "show", "succeeded");
      })
      .addCase(fetchShowScenes.fulfilled, (state, action) => {
        state.show.scenes = action.payload;
        updateStateStatus(state, "show", "succeeded");
      })
      .addCase(createBlock.fulfilled, (state, action) => {
        state.show = {
          ...state.show,
          blocks: state.show?.blocks
            ? [...state.show.blocks, action.payload]
            : [action.payload],
        };
        state.block = action.payload;

        updateStateStatus(state, "show", "succeeded");
      })
      .addCase(fetchBlockById.pending, (state) => {
        state.status.block = "loading";
        updateStateStatus(state, "block", "loading");
      })
      .addCase(fetchBlockById.fulfilled, (state, action) => {
        state.block = { ...state.block, ...action.payload };
        updateStateStatus(state, "block", "succeeded");
      })
      .addCase(fetchBlockById.rejected, (state, action) => {
        state.error.block = action.payload;
        updateStateStatus(state, "block", "failed", action);
      })
      .addCase(fetchBlockEquipments.fulfilled, (state, action) => {
        state.block.equipments = action.payload;
        updateStateStatus(state, "block", "succeeded");
      });
    //! no endpoint yet
    // no endpoint yet
    // .addCase(addSceneToShow.fulfilled, (state, action) => {
    //   state.show.scenes = [...state.show.scenes, action.payload];
    //   // updateStateStatus(state, 'show', 'succeeded');
    // })
    // .addCase(updateBlock.fulfilled, (state, action) => {
    //   state.block = action.payload;
    //   state.show.blocks = state.show.blocks.map((block) =>
    //     block.block_uuid === action.payload.block_uuid
    //       ? action.payload
    //       : block,
    //   );
    //   // updateStateStatus(state, 'block', 'succeeded'); // to uncomment  once I get endpoint fake async
    // });
  },
});

export const { clearShow } = showsSlice.actions;

export const showsStatus = (state) => state.shows.status;
export const showsError = (state) => state.shows.error;

export default showsSlice.reducer;
