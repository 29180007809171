// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const ColorWheel = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="4px"
      strokeLinecap="round"
    >
      <circle fill={color} stroke="none" cx="24" cy="11.2" r="10.1" />
      <line x1="9" y1="33.6" x2="39" y2="33.6" />
      <line x1="9" y1="40.2" x2="39" y2="40.2" />
      <line x1="9" y1="46.9" x2="39" y2="46.9" />
    </svg>
  );
};
