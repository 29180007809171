// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Check = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M20.01,32.5l17-17-17,17Z" strokeLinecap="round" />
      <path d="M20,32.5l-9.01-9,9.01,9Z" strokeLinecap="round" />
      <path d="M24,3c11.58,0,21,9.42,21,21s-9.42,21-21,21S3,35.58,3,24,12.42,3,24,3M24,2C11.85,2,2,11.85,2,24s9.85,22,22,22,22-9.85,22-22S36.15,2,24,2h0Z" />
    </svg>
  );
};
