// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useRef, useEffect, useCallback } from "react";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";

import styles from "./PopOver.module.css";

export const PopOver = ({
  buttonText,
  downIcon = true,
  position = "bottom",
  children,
  ...props
}) => {
  const { color, iconColor, classname, hoverEffect } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.button}>
        <Button
          color={color}
          onClick={() => setIsOpen(!isOpen)}
          classname={`${classname} ${isOpen && hoverEffect ? styles.active : ""}`}
        >
          <span className={styles.wrapper}>
            <Icons iconColor={iconColor} {...props} />
            {buttonText && buttonText}
          </span>
          {downIcon && (
            <Icons iconType="down" iconSize={12} iconColor={iconColor} />
          )}
        </Button>
      </div>
      {isOpen && (
        <div className={`${styles.content} ${styles[position]}`}>
          {children}
        </div>
      )}
    </div>
  );
};
