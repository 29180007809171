// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, {
  createContext,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../../web-utils/src/context/authContext";

import {
  wsConnect,
  wsErrorConnect,
  wsErrorMessage,
  sceneInStudioChanged,
} from "../../store/studio/studioSlice";

const REACT_APP_WEBSOCKET_URL =
  window.env?.REACT_APP_WEBSOCKET_URL ||
  "wss://api.dev.mosaic.mo-sys.com/api/v1/events/ui";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const { token } = useAuth();
  const { studio } = useSelector((state) => state.studio);
  const ws = useRef(null);
  const reconnectAttempt = useRef(0);
  const maxReconnectAttempts = 5; // Maximum reconnection attempts

  const dispatch = useDispatch();

  const send = (payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
    }
  };

  const connectWebSocket = useCallback(() => {
    if (token && studio?.studio_uuid) {
      // Encode the token in Base64
      const encodedToken = btoa(token);
      // Create a new WebSocket connection
      ws.current = new WebSocket(REACT_APP_WEBSOCKET_URL, encodedToken);

      // Handle WebSocket open event
      ws.current.onopen = () => {
        reconnectAttempt.current = 0;
        dispatch(wsConnect());
      };
      // Handle WebSocket message event
      ws.current.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response?.body) {
            const { event: eventName, data } = response.body;
            if (eventName === "SceneInStudioCurrentStateChanged") {
              dispatch(
                sceneInStudioChanged({
                  sceneUUID: data?.sceneUUID,
                  status: data?.state,
                  downloadPercentage: data?.progressPercentage,
                }),
              );
            }
          }
        } catch (error) {
          dispatch(
            wsErrorMessage("Unable to process incoming WebSocket message"),
          );
        }
      };

      ws.current.onerror = () => {
        dispatch(wsErrorConnect("Connection to studio failed"));
      };

      // Handle WebSocket close event
      ws.current.onclose = () => {
        // Attempt to reconnect
        if (reconnectAttempt.current < maxReconnectAttempts) {
          reconnectAttempt.current += 1;
          setTimeout(connectWebSocket, 2000);
        } else {
          // Give up reconnection after 5 attempts to give user feedbacks
          // potentially remove and always reconnect. see: https://gitlab.mo-sys.com/CCS/project-c-ui/-/merge_requests/1#note_34242
          dispatch(wsErrorConnect("Connection to studio failed"));
        }
      };
      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    }
  }, [token, studio?.studio_uuid, dispatch]);

  useEffect(() => {
    connectWebSocket();
  }, [connectWebSocket]);

  // register studio
  useEffect(() => {
    if (studio?.connected && studio?.studio_uuid) {
      send({
        type: "register",
        body: {
          event: "AllScenesInStudioCurrentStateChanged",
          data: {
            studioUUID: studio?.studio_uuid,
          },
        },
      });
    }
  }, [studio?.connected, studio?.studio_uuid]);

  // Close WebSocket connection when studio change
  const closeWebSocket = useCallback(() => {
    if (ws.current) {
      send({
        type: "deregister",
        body: {
          event: "AllScenesInStudioCurrentStateChanged",
          data: {
            studioUUID: studio?.studio_uuid,
          },
        },
      });
      ws.current.close();
    }
  }, [studio?.studio_uuid]);

  return (
    <WebSocketContext.Provider
      value={{ send, closeWebSocket, connectWebSocket }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const context = useContext(WebSocketContext);
  return context;
};
