// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const Users = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="14.96" cy="16.25" r="6.35" />
      <circle cx="31.15" cy="13.39" r="8.1" />
      <path d="M44.41,28.93c0,2.86-1.27,5.97-3.38,8.5-2.53,3.05-6.27,5.28-10.45,5.28-3.91,0-7.44-1.96-9.96-4.73-2.39-2.62-3.85-5.97-3.86-9.05,0-1.22,1.68-2.13,4.21-2.75,5.06-1.23,13.52-1.26,18.77-.11,2.78.62,4.67,1.58,4.67,2.86Z" />
      <path d="M16.76,28.93c.01,3.08,1.47,6.43,3.86,9.05-.7.66-2.07,1.47-6.14,1.47-5.96,0-10.89-5.97-10.89-10.89,0-2.76,11.03-3.56,17.38-2.38-2.53.62-4.21,1.53-4.21,2.75Z" />
    </svg>
  );
};
