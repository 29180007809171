// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createSlice } from "@reduxjs/toolkit";

import { addAsyncCases, updateStateStatus, clearState } from "../utils";

import {
  fetchScenes,
  fetchSceneById,
  addKeywordToScene,
  deleteKeywordFromScene,
  filteredScenes,
  updateScene,
  deleteScene,
  fetchUnrealVersion,
} from "./scenesActions";

const initialState = {
  scenes: [],
  selectedScene: null,
  filterScenes: [],
  unrealVersions: [],
  status: {
    // possible status state : 'idle' | 'loading' | 'succeeded' | 'failed'
    scenes: "idle",
    selectedScene: "idle",
    filterScenes: "idle",
    unrealVersions: "idle",
  },
  error: {
    scenes: null,
    selectedScene: null,
    filterScenes: null,
    unrealVersions: null,
  },
};

const sceneSlice = createSlice({
  name: "scenes",
  initialState,
  reducers: {
    clearFilteredList: (state) => {
      state.filterScenes = [];
      state.error.filterScenes = null;
    },
    clearSelectedScene: (state) => {
      state.selectedScene = null;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, [
      { action: fetchScenes, key: "scenes" },
      { action: fetchSceneById, key: "selectedScene" },
      { action: filteredScenes, key: "filterScenes" },
      { action: fetchUnrealVersion, key: "unrealVersions" },
    ]);
    builder
      .addCase(clearState, () => initialState) // Reset state on logout
      .addCase(updateScene.fulfilled, (state, action) => {
        state.scenes = state.scenes.map((scene) =>
          scene.scene_id === action.payload.scene_id ? action.payload : scene,
        );
        if (state.selectedScene?.scene_id === action.payload.scene_id) {
          state.selectedScene = action.payload;
        }
        updateStateStatus(state, "scenes", "succeeded");
      })
      .addCase(deleteScene.fulfilled, (state, action) => {
        state.scenes = state.scenes.filter(
          (s) => s.scene_id !== action.action.payload.scene_id,
        );
        updateStateStatus(state, "scenes", "succeeded");
      })
      .addCase(addKeywordToScene.fulfilled, (state, action) => {
        const sceneIndex = state.scenes.findIndex(
          (scene) => scene.scene_id === action.payload.scene_id,
        );
        if (sceneIndex !== -1) {
          state.scenes[sceneIndex] = {
            ...state.scenes[sceneIndex],
            keywords: [
              ...(state.scenes[sceneIndex].keywords || []),
              action.payload,
            ],
          };
        }
        if (state.selectedScene?.scene_id === action.payload.scene_id) {
          state.selectedScene = {
            ...state.selectedScene,
            keywords: [
              ...(state.selectedScene?.keywords || []),
              action.payload,
            ],
          };
        }
        updateStateStatus(state, "scenes", "succeeded");
      })
      .addCase(deleteKeywordFromScene.fulfilled, (state, action) => {
        const { keywordId, sceneId } = action.payload;
        const sceneIndex = state.scenes.findIndex(
          (scene) => scene?.scene_uuid === sceneId,
        );
        if (sceneIndex !== -1) {
          state.scenes[sceneIndex] = {
            ...state.scenes[sceneIndex],
            keywords:
              state.scenes[sceneIndex].keywords?.filter(
                (keyword) => keyword?.keyword?.keyword_uuid !== keywordId,
              ) || [],
          };
        }
        if (state.selectedScene?.scene_uuid === sceneId) {
          state.selectedScene = {
            ...state.selectedScene,
            keywords: state.selectedScene?.keywords?.filter(
              (keyword) => keyword?.keyword?.keyword_uuid !== keywordId,
            ),
          };
        }
        updateStateStatus(state, "scenes", "succeeded");
      });
  },
});

export const { clearFilteredList, clearSelectedScene } = sceneSlice.actions;

export const scenesStatus = (state) => state.scenes.status;
export const scenesError = (state) => state.scenes.error;

export default sceneSlice.reducer;
