// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { Outlet } from "react-router";

// TODO : if user is not a member of company block access and redirect to modal to select companies
function ProtectedCompanyRoute({ children }) {
  return (
    <>
      <Outlet />
    </>
  );
}

export default ProtectedCompanyRoute;
