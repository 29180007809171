// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../services/axiosInstance";
import { apiCall } from "../utils";

// get User
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("user/me/"), rejectWithValue),
);

// get user invites
export const fetchUserInvites = createAsyncThunk(
  "user/fetchUserInvites",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("user/member/invites/"), rejectWithValue),
);

// accept company member invitaion
export const acceptCompanyInvitation = createAsyncThunk(
  "company/acceptCompanyInvitation",
  async (data, { rejectWithValue }) =>
    apiCall(
      () => axiosInstance.post(`company/member/accept-invite/`, data),
      rejectWithValue,
    ),
);

// get all users / superUser permission only //! not used
export const fetchUsersAll = createAsyncThunk(
  "user/fetchUsersAll",
  async (_, { rejectWithValue }) =>
    apiCall(() => axiosInstance.get("user/all/"), rejectWithValue),
);

// get users user/filtered/
export const fetchCompanyUsers = createAsyncThunk(
  "user/fetchCompanyUsers",
  async (data, { rejectWithValue }) => {
    const updatedData = {
      company_uuid: data?.companyId,
      company_role: data?.companyRole || null,
      company_role_level: data?.companyRoleLevel || "Single level",
      include_disabled: data?.disabled || null,
    };
    return apiCall(
      () => axiosInstance.post("user/filtered/", updatedData),
      rejectWithValue,
    );
  },
);
