// Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

export const LinkExternal = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="3px"
    >
      <path
        d="M45.77,23.18l-.02,19.83c0,1.64-1.33,2.96-2.96,2.96H5.2c-1.63,0-2.96-1.32-2.96-2.96V4.99c0-1.64,1.33-2.96,2.96-2.96l20.46-.05"
        strokeLinecap="round"
      />

      <line x1="18.3" y1="29.17" x2="45.13" y2="2.63" strokeLinecap="round" />
      <polygon points="45.65 7.87 44.65 7.82 44.86 2.9 39.93 3.05 39.9 2.05 45.9 1.87 45.65 7.87" />
    </svg>
  );
};
